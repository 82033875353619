// Asset constants
export const ARROW_LEFT = "/static/images/homepage/arrow-left.svg";
export const ARROW_RIGHT = "/static/images/homepage/arrow-right.svg";
export const ASURANSI_KARYAWAN_ICON = "/static/images/homepage/asuransi-karyawan.svg";
export const ASURANSI_KESEHATAN_ICON = "/static/images/homepage/asuransi-kesehatan.svg";
export const ASURANSI_MOBIL_ICON = "/static/images/homepage/asuransi-mobil.svg";
export const ASURANSI_MOTOR_ICON = "/static/images/homepage/asuransi-motor.svg";
export const BRAND_ACA_ICON = { icon: "/static/images/homepage/brand-aca.svg", url: '/asuransi/mobil-aca/' };
export const BRAND_APRIL_ICON = { icon: "/static/images/homepage/brand-april.svg", url: '/asuransi/april-international/' };
export const BRAND_ASTRA_ICON = { icon: "/static/images/homepage/brand-astra.svg", url: '/asuransi/mobil-garda-oto/' };
export const BRAND_AXA_MANDIRI_ICON = { icon: "/static/images/homepage/brand-axa-mandiri.svg", url: '/asuransi/kesehatan-axa/' };
export const BRAND_CAR_LIFE_ICON = { icon: "/static/images/homepage/brand-car-life.svg", url: '/asuransi/kesehatan-car/' };
export const BRAND_KB_ICON = { icon: "/static/images/homepage/brand-kb.svg", url: '/asuransi/kb-insurance/' };
export const BRAND_LGI_ICON = { icon: "/static/images/homepage/brand-lgi.svg", url: '/asuransi/kesehatan-lippo/' };
export const BRAND_MAG_ICON = { icon: "/static/images/homepage/brand-mag.svg", url: '/asuransi/mag/' };
export const BRAND_MEGA_ICON = { icon: "/static/images/homepage/brand-mega.svg", url: '/asuransi/mobil-mega-insurance/' };
export const BRAND_SIMAS_INSURTECH_ICON = { icon: "/static/images/homepage/brand-simas-insurtech.svg", url: '/asuransi/simas-insurtech/' };
export const BRAND_SOMPO_BY_ROOJAI_ICON = { icon: "/static/images/homepage/brand-sompo-by-roojai.svg", url: '/asuransi/mobil-sompo/' };
export const BRAND_SUN_LIFE_ICON = { icon: "/static/images/homepage/brand-sun-life.svg", url: '/asuransi/sunlife/' };
export const BRAND_ZURICH_SYARIAH_ICON = { icon: "/static/images/homepage/brand-zurich-syariah.svg", url: '/asuransi/zurich-syariah/' };
export const CALL_ME_CHEETAH_IMAGE = '/static/images/call-me-cheetah.svg';
export const CHECK_CIRCLE_SOLID_ICON = "/static/images/homepage/check-circle-solid.svg";
export const HEADSET_ICON = '/static/images/homepage/headset.svg';
export const MASCOT_CHEETAH_IMAGE = "/static/images/homepage/mascot-cheetah.svg";
export const MASCOT_CIRCLE_AVATAR_ICON = '/static/images/icons/mascot-circle-avatar.svg';
export const MINUS_ICON = "/static/images/how-to-buy/minus-icon.svg";
export const OUTLINE_CHECK_ACTIVE_ICON = '/static/images/icons/outline-check-active.svg';
export const OUTLINE_CHECK_ICON = '/static/images/icons/outline-check.svg';
export const PLUS_ICON = "/static/images/how-to-buy/plus-icon.svg";
export const RECOMMENDED_ARTICLE_1 = "/static/images/homepage/recommended-article-1.jpg";
export const RECOMMENDED_ARTICLE_2 = "/static/images/homepage/recommended-article-2.jpg";
export const RECOMMENDED_ARTICLE_3 = "/static/images/homepage/recommended-article-3.jpg";
export const RECOMMENDED_ARTICLE_4 = "/static/images/homepage/recommended-article-4.jpg";
export const RED_ALERT_ICON = "/static/images/icons/red-alert.svg";
export const WHY_LIFEPAL_DISCOUNT_ICON = "/static/images/homepage/why-lifepal-discount.svg";
export const WHY_LIFEPAL_FREE_ICON = "/static/images/homepage/why-lifepal-free.svg";
export const WHY_LIFEPAL_RECOMMENDED_ICON = "/static/images/homepage/why-lifepal-recommended.svg";
export const WHY_LIFEPAL_RELIABLE_ICON = "/static/images/homepage/why-lifepal-reliable.svg";
export const WHY_LIFEPAL_TIME_ICON = "/static/images/homepage/why-lifepal-time.svg";

// Associated brands
export const ASSOCIATED_BRANDS = [
  BRAND_ASTRA_ICON,
  BRAND_ZURICH_SYARIAH_ICON,
  BRAND_ACA_ICON,
  BRAND_SOMPO_BY_ROOJAI_ICON,
  BRAND_MEGA_ICON,
  BRAND_SIMAS_INSURTECH_ICON,
  BRAND_AXA_MANDIRI_ICON,
  BRAND_SUN_LIFE_ICON,
  BRAND_LGI_ICON,
  BRAND_APRIL_ICON,
  BRAND_KB_ICON,
  BRAND_MAG_ICON,
  BRAND_CAR_LIFE_ICON,
];

//Media
export const MEDIA_WEB = "/static/images/homepage/media-web.svg";
export const MEDIA_MOBILE = "/static/images/homepage/media-mobile.svg";

// Review Google JSON
export const REVIEW_LIFEPAL_JSON = [
  {
    id: 1,
    alt: "review",
    name: "Dessy Farahdila",
    review: `
            Untuk proses pengajuan asuransi sangat mudah, consultan sangan membantu dan memberi penjelasan sangat detail sampe ke beberapa bengkel rekanan jg terindo detail,  proses persetujuan jg mudah dan simple. Untuk proses semoga jg mudah sperti saat proses pengajuan / registrasi asuransinya.
          `,
  },
  {
    id: 2,
    alt: "review",
    name: "Bintang Situngkir",
    review: `
            Saya membeli asuransi lewat internet,pelayanan cepat lewat lifepal,dan pengalaman saya saat claim cepat dilayani yang diarahkan dengan rekanan asuransi sinarmas datang langsung proses.
          `,
  },
  {
    id: 3,
    alt: "review",
    name: "Fuady Primaharsya",
    review: `
            Marketplace asuransi yang mudah dan murah. Berbagai pilihan asuransi diberikan dengan penjelasan yang baik dan komprehensif, tentunya dengan diskon yang menarik. Terima kasih Lifepal, insya Allah jadi langganan dalam berasuransi.
          `,
  },
  {
    id: 4,
    alt: "review",
    name: "Moch Ridwan Akbar",
    review: `
            Saya sangat puas menggunakan lifepal asuransi untuk mobil saya, saya menggunakan 2 mobil, mendapatkan kemudahan dalam inspeksi karna menggunakan video call untuk pengecekan mobilnya dan proses nya hanya beberapa jam dari saat inspeksi. Sangat cepat dan sangat puas.
          `,
  },
  {
    id: 5,
    alt: "review",
    name: "Alung Abdee Ello",
    review: `
            Pengajuan sangat mudah dan gampang, proses nya cepat dan dipandu oleh petugas yang ramah dan dengan intruksi yang jelas dan mudah di pahami. Ini kali kedua memilih mebeli asuransi mobil menggunakan lifepal. Layanannya terbaik.
          `,
  },
  {
    id: 6,
    alt: "review",
    name: "Heryanto Yohanes",
    review: `
            Baru pertama kali coba apply asuransi via lifepal. Proses simple, fast, reliable. Dari proses penawaran, sampai dihitungkan asuransi yg memberikan benefit terbaik dg premi yang bersahabat.
          `,
  },
  {
    id: 7,
    alt: "review",
    name: "Febiola Widjaja",
    review: `
            udah tahun ke 3 bikin asuransi mobil via Lifepal. adminnya baik2 dan sabar banget, prosesnya gercep dan gak pake lama, harganya juga sangat oke. Polis pdf dan hardcopy cepet dikiriminnya.. semua asuransi yg kerjasama juga legit, dan gampang pengajuan klaimnya.. Mantap pokoknya, akan langganan terus tiap tahun..
          `,
  },
];

// Why Lifepal JSON
export const WHY_LIFEPAL_JSON = [
  {
    icon: WHY_LIFEPAL_DISCOUNT_ICON,
    title: "Beneran Hemat",
    description:
      "Lifepal menyediakan pilihan asuransi terkemuka paling lengkap di Indonesia. Cukup jawab beberapa pertanyaan dan dapatkan polis terbaik dengan premi termurah.",
  },
  {
    icon: WHY_LIFEPAL_RECOMMENDED_ICON,
    title: "Beneran Netral",
    description:
      "Konsultan Lifepal akan memberikan plan asuransi terbaik yang paling sesuai dengan kebutuhan dan anggaran kamu. Layanan konsultasi gratis!",
  },
  {
    icon: WHY_LIFEPAL_FREE_ICON,
    title: "Beneran Ngebantu",
    description:
      "Lifepal dapat membantu pastikan proses klaim yang diberikan oleh pihak asuransi sesuai dengan yang dijanjikan. Cukup hubungi kami dan Lifepal siap membantumu!",
  },
  {
    icon: WHY_LIFEPAL_TIME_ICON,
    title: "Beneran Cepet",
    description:
      "Mulai dari bandingkan asuransi hingga polis diterbitkan, Lifepal menggunakan teknologi untuk membuat prosesnya lebih cepat dan mudah untuk kamu.",
  },
  {
    icon: WHY_LIFEPAL_RELIABLE_ICON,
    title: "Beneran Terpercaya",
    description:
      "Memiliki ulasan positif dari nasabah dengan rating 4,5 di Google Review. Berlisensi dan berada di bawah pengawasan OJK.",
  },
];

// Recommended articles
export const RECOMMENDED_ARTICLES = [
  {
    image: RECOMMENDED_ARTICLE_1,
    title: "9 Asuransi Mobil All Risk Terbaik yang Bisa Jadi Pilihan",
    label: "Asuransi Mobil",
    date: "15 Desember 2023",
    link: "https://lifepal.co.id/media/asuransi-mobil-all-risk-terbaik/",
  },
  {
    image: RECOMMENDED_ARTICLE_2,
    title: "Biaya Asuransi Mobil All Risk dan TLO Menurut OJK",
    label: "Asuransi Mobil",
    date: "22 Januari 2024",
    link: "https://lifepal.co.id/media/rate-asuransi-mobil/",
  },
  {
    image: RECOMMENDED_ARTICLE_3,
    title: "Rekomendasi Asuransi Mobil Murah Terbaik di Indonesia",
    label: "Asuransi Mobil",
    date: "5 Mei 2023",
    link: "https://lifepal.co.id/media/pilihan-asuransi-mobil-murah/",
  },
  {
    image: RECOMMENDED_ARTICLE_4,
    title: "Kenali Jenis-Jenis Asuransi Mobil dan Manfaat Selengkapnya!",
    label: "Asuransi Mobil",
    date: "30 September 2024",
    link: "https://lifepal.co.id/media/perbandingan-asuransi-mobil/",
  },
];

// How to buy static items
export const HOW_TO_BUY = {
  car: {
    title: "Langkah-Langkah Beli Asuransi Mobil Online",
    content: [
      {
        text: "Kunjungi situs kami di Lifepal.co.id dan pilih produk <b>Asuransi Mobil</b>.",
        image: "/static/images/how-to-buy/car.svg",
      },
      {
        text: "Isi formulir dan jawab beberapa pertanyaan untuk bantu kami memberikan rekomendasi terbaik sesuai profil mobilmu.",
        image: "/static/images/how-to-buy/form.svg",
      },
      {
        text: "Tim Lifepal akan segera menghubungi kamu untuk memberikan penawaran dan perbandingkan polis terbaik sesuai kebutuhanmu.",
        image: "/static/images/how-to-buy/compare.svg",
      },
      {
        text: "Pilih polis yang diinginkan.",
        image: "/static/images/how-to-buy/buy.svg",
      },
      {
        text: "Quotation dan payment link akan segera dikirimkan ke alamat email kamu.",
        image: "/static/images/how-to-buy/checkout.svg",
      },
      {
        text: "Klik payment link dan selesaikan pembayaran.",
        image: "/static/images/how-to-buy/pay.svg",
      },
      {
        text: "Proses inspeksi mobil dilakukan secara online melalui video call.",
        image: "/static/images/how-to-buy/inspect.svg",
      },
      {
        text: "Polis asuransi mobil kamu segera kami proses.",
        image: "/static/images/how-to-buy/process.svg",
      },
    ],
    documents:
      "- Kartu Tanda Pengenal (KTP)\n- Surat Izin Mengemudi (SIM)\n- Surat Tanda Nomor Kendaraan (STNK)",
  },
  health: {
    title: "Langkah-Langkah Beli Asuransi Kesehatan Online",
    content: [
      {
        text: "Kunjungi situs kami di Lifepal.co.id dan pilih produk <b>Asuransi Kesehatan</b>.",
        image: "/static/images/how-to-buy/health.svg",
      },
      {
        text: "Isi formulir dan jawab beberapa pertanyaan untuk bantu kami memberikan rekomendasi terbaik sesuai kebutuhanmu.",
        image: "/static/images/how-to-buy/form.svg",
      },
      {
        text: "Tim Lifepal akan segera menghubungi kamu untuk memberikan penawaran dan perbandingkan polis terbaik sesuai kebutuhanmu.",
        image: "/static/images/how-to-buy/compare.svg",
      },
      {
        text: "Pilih polis yang diinginkan.",
        image: "/static/images/how-to-buy/buy.svg",
      },
      {
        text: "Quotation dan payment link akan segera dikirimkan ke alamat email kamu.",
        image: "/static/images/how-to-buy/checkout.svg",
      },
      {
        text: "Klik payment link dan selesaikan pembayaran.",
        image: "/static/images/how-to-buy/pay.svg",
      },
      {
        text: "Polis asuransi kesehatan kamu segera kami proses.",
        image: "/static/images/how-to-buy/process.svg",
      },
    ],
    documents:
      "- Kartu Tanda Pengenal (KTP)\n- Kartu Keluarga\n- Passport (khusus untuk WNA)\n- KITAS (khusus untuk WNA)",
  },
};

// FAQ Items
export const FAQ_ITEMS = [
  {
    title: "Apa itu Lifepal?",
    content: () => (
      <p className="faq-item-content">
        Lifepal adalah marketplace asuransi yang berdiri sejak tahun 2018 dengan
        misi untuk memberikan pilihan asuransi berkualitas tinggi yang paling
        lengkap di Indonesia. Kami berkomitmenuntuk membantu nasabah membuat
        keputusan asuransi yang tepat, sesuai dengan anggaran dan kebutuhan
        mereka.
      </p>
    ),
  },
  {
    title:
      "Apa bedanya marketplace asuransi Lifepal dengan perusahaan asuransi?",
    content: () => (
      <p className="faq-item-content">
        Sebagai marketplace asuransi, Lifepal bertindak sebagai perantara antara
        nasabah dan perusahaan asuransi. Karena itu, Lifepal memberikan panduan
        dan rekomendasi yang objektif, sehingga dapat membantu setiap calon
        nasabah mendapatkan proteksi asuransi terbaik yang lebih personal.
      </p>
    ),
  },
  {
    title: "Bagaimana Lifepal mendapatkan keuntungan?",
    content: () => (
      <p className="faq-item-content">
        Lifepal menerima komisi dari perusahaan asuransi untuk setiap polis yang
        berhasil dijual. Komisi ini berupa persentase dari premi polis yang
        dibayar oleh nasabah. Besaran komisi disesuaikan dengan perjanjian
        antara pihak Lifepal sebagai marketplace asuransi dan perusahaan
        asuransi.
      </p>
    ),
  },
  {
    title: "Apakah Lifepal sudah terdaftar di Otoritas Jasa Keuangan (OJK)?",
    content: () => (
      <p className="faq-item-content">
        Tentu, Lifepal beroperasi di bawah izin PT Anugrah Atma Adiguna yang
        sudah terdaftar dan diawasi oleh OJK sesuai KEP-018/KMK.17/1992 dan
        anggota APPARINDO 60-2001/APPARINDO/2024.
      </p>
    ),
  },
  {
    title: "Bagaimana cara membeli dan daftar asuransi online melalui Lifepal?",
    content: () => (
      <p className="faq-item-content">
        Berikut ini adalah langkah-langkah{" "}
        <a href="https://lifepal.co.id/cara-beli/">
          membeli asuransi secara online
        </a>{" "}
        di Lifepal.
        <ul>
          <li>
            Langkah 1: Kunjungi situs lifepal.co.id, pilih jenis asuransi yang
            diinginkan.
          </li>
          <li>
            Langkah 2: Isi formulir data diri atau profil kendaraan untuk produk
            asuransi mobil. Kemudian klik Tampilkan Pilihan Polis.
          </li>
          <li>
            Langkah 3: Tim Lifepal akan segera menghubungimu untuk memberikan
            penawaran dan perbandingan polis yang sesuai dengan profilmu.
          </li>
          <li>Langkah 4: Pilih polis yang diinginkan.</li>
          <li>
            Langkah 5: Quotation dan payment link akan segera dikirimkan ke
            alamat email kamu.
          </li>
          <li>Langkah 6: Klik payment link dan selesaikan pembayaran.</li>
          <li>
            Langkah 7: Khusus untuk produk asuransi mobil, akan dilakukan proses
            inspeksi secara online.
          </li>
          <li>
            Langkah 8: Polis kamu segera kami proses dan akan dikirim melalui
            email.
          </li>
        </ul>
      </p>
    ),
  },
];

export const HOMEPAGE_PROMO = [
  {
    id: 0,
    imgURL: '/static/images/promos/home_banner1.png',
    fallbackImgURL: '/static/images/promos/home_banner1.png',
    alt: 'Promo Merdeka',
    redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=MV_YESVember',
  },
  {
    id: 1,
    imgURL: '/static/images/promos/home_banner2.png',
    fallbackImgURL: '/static/images/promos/home_banner2.png',
    alt: 'Promo Asuransi Kesehatan',
    redirectURL: '/asuransi/kesehatan/?utm_source=homepage&utm_medium=banner&utm_campaign=promo_asuransi_kesehatan',
  },
  {
    id: 2,
    imgURL: '/static/images/promos/home_banner3.png',
    fallbackImgURL: '/static/images/promos/home_banner3.png',
    alt: 'Promo 15 Tahun',
    redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=MV_promo_voucher',
  },
  {
    id: 3,
    imgURL: '/static/images/promos/home_banner4.png',
    fallbackImgURL: '/static/images/promos/home_banner4.png',
    alt: 'Promo Asuransi Kesehatan Keluarga',
    redirectURL: '/asuransi/kesehatan-keluarga/?utm_source=homepage&utm_medium=banner&utm_campaign=promo_asuransi_keluarga',
  },
  {
    id: 4,
    imgURL: '/static/images/promos/home_banner5.png',
    fallbackImgURL: '/static/images/promos/home_banner5.png',
    alt: 'Bandingkan Asuransi Terbaik',
    redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=homepage+compare',
  },
  {
    id: 5,
    imgURL: '/static/images/promos/home_banner6.png',
    fallbackImgURL: '/static/images/promos/home_banner6.png',
    alt: 'Perbaikan di Bengkel Resmi',
    redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=MV_cicilan',
  }
]

export const COMPANY_VALUE = [
  {
    title: "Mengutamakan Nasabah (Customer First)",
    subtitle:
      "Lifepal memastikan bahwa setiap produk dan layanan yang kami berikan dirancang dengan mempertimbangkan nasabah. Dengan terus berinovasi, Lifepal secara konsisten memberikan pengalaman terbaik dalam menghadirkan proteksi asuransi yang dapat dipersonalisasi untuk memenuhi kebutuhan setiap nasabah. ",
    image: "/static/images/company_value_1.png",
  },
  {
    title: "Rasa Kepemilikan (Ownership)",
    subtitle:
      "Lifepal berdedikasi untuk memelihara kepercayaan nasabah dengan senantiasa memberikan layanan terbaik sepenuh hati. Dengan tim yang kuat dan berpengalaman dibidangnya, Lifepal memastikan setiap kebutuhan nasabah dapat dipenuhi dengan tanggung jawab serta dedikasi tinggi.",
    image: "/static/images/company_value_2.png",
  },
  {
    title: "Integritas (Integrity)",
    subtitle:
      "Lifepal menghargai kepercayaan yang diberikan nasabah kepada kami. Karena itu, kami terus berusaha untuk selalu transparan dan secara konsisten selaras dengan nilai-nilai yang kami pegang dalam setiap interaksi dengan nasabah.",
    image: "/static/images/company_value_3.png",
  },
];

export const BEHIND_LIFEPAL = [
  {
    name: "Eko Waluyo",
    position: "Presiden Direktur",
    text: "Eko Waluyo memiliki pengalaman lebih dari 10 tahun dalam memimpin sejumlah perusahaan broker asuransi ternama di Indonesia. Sukses memimpin Rajawali Insurance Brokers dan Nusantara Insurance Broker & Consultant, kini Eko Waluyo menjabat sebagai Presiden Direktur  dari PT Anugrah Atma Adiguna.  ",
    image: "/static/images/behind-lifepal-1.svg",
  },
  {
    name: "Bambang ROCHYARSYAM",
    position: "Direktur",
    text: "Bambang Rochyarsyam memiliki pengalaman lebih dari 30 tahun dalam industri asuransi di Indonesia. Dengan latar belakang memimpin berbagai perusahaan asuransi, termasuk Berdikari Insurance dan Rajawali Insurance Brokers, saat ini Bambang Rochyarsyam menjabat sebagai Direktur di PT Anugrah Atma Adiguna. ",
    image: "/static/images/behind-lifepal-2.svg",
  },
];

export const USP_PANEL = {
  auto: [
    {
      image: "/static/images/icons/type-active.svg",
      text: "Diskon dan reward menarik dari 50+ pilihan Asuransi",
      alt: "diskon dan reward"
    },
    {
      image: "/static/images/icons/car.svg",
      text: "800+ bengkel rekanan di seluruh Indonesia, sparepart original!",
      alt: "bengkel rekanan"
    },
    {
      image: "/static/images/icons/mdi_car-clock.svg",
      text: "Pengaktifan polis cepat < 10 menit dengan inspeksi kendaraan online",
      alt: "avatar"
    },
    {
      image: "/static/images/homepage/headset.svg",
      text: "Konsultasi gratis dengan konsultan berpengalaman",
      alt: "avatar"
    }
  ],
  moto: [
    {
      image: "/static/images/icons/type-active.svg",
      text: "Bandingkan antara 13+ pilihan asuransi",
      alt: "diskon dan reward"
    },
    {
      image: "/static/images/icons/mdi_car-clock.svg",
      text: "Pengaktifan polis cepat dengan inspeksi kendaraan online",
      alt: "avatar"
    },
    {
      image: "/static/images/homepage/headset.svg",
      text: "Konsultasi gratis dengan konsultan berpengalaman",
      alt: "avatar"
    }
  ],
  others: [
    {
      image: "/static/images/icons/type-active.svg",
      text: "Bandingkan antara 13+ pilihan asuransi",
      alt: "diskon dan reward"
    },
    {
      image: "/static/images/icons/office-building.svg",
      text: "Rumah sakit rekanan tersebar di seluruh Indonesia",
      alt: "bengkel rekanan"
    },
    {
      image: "/static/images/icons/mdi_car-clock.svg",
      text: "Mulai dari membandingkan asuransi, hingga polis terbit, semuanya bisa dilakukan online",
      alt: "avatar"
    },
    {
      image: "/static/images/homepage/headset.svg",
      text: "Konsultasi gratis dengan konsultan berpengalaman",
      alt: "avatar"
    }
  ]
}
